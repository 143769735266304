import { parseQueryString } from 'helpers/url'
import { removeAlipayParams } from 'helpers/registrations'
import Api from 'services/api'
import { queryClient } from 'services/query_client'
import {
  saveAuthToken,
  deleteAuthToken,
  deleteCookieWithoutReload
} from 'services/cookies'
import {
  sendSegmentTrackAndIdentifyEvents,
  sendSegmentTrackEvent,
  safeSegmentCall
} from 'services/segment'
import { redirectToLogin } from 'helpers/redirects'
import { getProductForSegment } from 'helpers/segment'
import * as type from 'constants/action_types'
import { RESET_GLOBAL_DATA } from 'constants/action_types/global'
import { getMixpanelId } from 'helpers/mixpanel'
import {
  clearFlagsData,
  clearGlobalData,
  clearUsabillaLiveData
} from 'helpers/storage'
import { createNotification } from './notifications'
import { clearChurnZero } from '../config/churnZero'

const setEmailForMetrics = email => {
  if (!window._sift) {
    window._sift = []
  }
  const { _sift } = window
  _sift.push(['_setUserId', email])
  return window.profitwell('user_email', email)
}

const setAppType = (appType = 'default') => dispatch =>
  dispatch({ type: type.SET_GLOBAL_DATA, payload: { appType } })

export const addAdditionalUser = data => async dispatch => {
  const res = await Api.addAdditionalUser(data)

  if (res.ok) {
    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Registration:'
      })
    )
    window.location.hash = res.data.route
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Registration error:'
      })
    )
    window.location.hash = 'account'
  }
}

export const sessionHandleInputChange = (e, data) => dispatch => {
  const target = e?.currentTarget
  const prop = target?.getAttribute('label')
  const value = target?.value

  dispatch({
    type: type.ADD_SESSION_VALIDATION_ERROR,
    payload: {
      field: prop,
      message: ''
    }
  })

  dispatch({
    type: type.SESSION_INPUT_FIELD_UPDATE,
    payload: {
      prop: e ? prop : data.prop, // For cases when we set values manually
      value: e ? value : data.value
    }
  })
}

export const loginUser = (data = {}) => async (dispatch, getState) => {
  const { redirectRoute } = parseQueryString(window.location.href)
  const { email: sessionEmail, password: sessionPassword } = getState().sessions
  const { email, password, skipOnboard, updatedDesign } = data
  // this looks a bit weird - but we don't want to accidentally pull a previous user's data over
  // so before doing anything else - clear out global data
  clearGlobalData()

  dispatch({
    type: type.PENDING_SESSION_ACTION,
    payload: 'login'
  })

  const mixpanelId = getMixpanelId()

  const res = await Api.loginUser({
    email: email || sessionEmail,
    password: password || sessionPassword,
    redirectRoute,
    skipOnboard,
    mixpanelId
  })

  if (res.ok) {
    dispatch({
      type: type.SESSION_INPUT_FIELD_UPDATE,
      payload: {
        prop: 'password',
        value: ''
      }
    })

    const { verifyAuthy, token, flagData, appType } = res.data

    setEmailForMetrics(email)
    dispatch(setAppType(appType))

    if (verifyAuthy) {
      dispatch({
        type: type.TOGGLE_AUTHY_MODAL
      })
    } else {
      saveAuthToken(token)
      window.location.hash = res.data.route
      if (skipOnboard) {
        // Need to clear Alipay's parameters from the url string
        window.history.replaceState(
          {},
          document.title,
          removeAlipayParams(window.location.href)
        )
      }
    }

    // we do this as it's own action up front to avoid race conditions
    if (flagData) {
      dispatch({
        type: type.LOADED_FEATURE_FLAGS,
        payload: {
          flagData
        }
      })
    }

    dispatch({
      type: type.COMPLETED_SESSION_ACTION,
      payload: 'login'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_SESSION_ACTION,
        payload: 'login'
      })
      const user = getState().globalData?.user
      sendSegmentTrackAndIdentifyEvents(
        'Signed In',
        {
          email: email || sessionEmail,
          product: getProductForSegment(appType)
        },
        user.id
      )
    }, 5000)
  } else {
    dispatch({
      type: type.RESET_SESSION_ACTION,
      payload: 'login'
    })

    // In the updated design, we display custom error on the form itself, instead of error toasted
    if (updatedDesign) {
      dispatch({
        type: type.SESSION_INPUT_FIELD_UPDATE,
        payload: {
          prop: 'error',
          value: Math.random() // need to get random error value in order to show/hide error block on th UI
        }
      })
    } else {
      dispatch(
        createNotification({
          message: res.error,
          level: 'error',
          title: 'Log in error:'
        })
      )
    }
  }
}

export const loginAndAddUser = ({ m: additionalUserToken }) => async (
  dispatch,
  getState
) => {
  const { email, password } = getState().registrations

  dispatch({
    type: type.PENDING_SESSION_ACTION,
    payload: 'login'
  })

  const mixpanelId = getMixpanelId()

  const res = await Api.loginUser({
    email,
    password,
    mixpanelId,
    skipAppTypeVerification: true
  })

  if (res.ok) {
    const { verifyAuthy, token, appType } = res.data
    setEmailForMetrics(email)
    dispatch(setAppType(appType))

    if (verifyAuthy) {
      dispatch({
        type: type.TOGGLE_AUTHY_MODAL
      })
    } else {
      saveAuthToken(token)
      await dispatch(
        addAdditionalUser({
          email,
          token: additionalUserToken
        })
      )
    }

    dispatch({
      type: type.COMPLETED_SESSION_ACTION,
      payload: 'login'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_SESSION_ACTION,
        payload: 'login'
      })
    }, 5000)
  } else {
    dispatch({
      type: type.RESET_SESSION_ACTION,
      payload: 'login'
    })
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Log in error:'
      })
    )
  }
}

export const loginAndDeleteUser = (data = {}) => async dispatch => {
  const { email, password } = data

  // this looks a bit weird - but we don't want to accidentally pull a previous user's data over
  // so before doing anything else - clear out global data
  clearGlobalData()

  const mixpanelId = getMixpanelId()

  const res = await Api.loginUser({
    email,
    password,
    mixpanelId
  })

  if (res.ok) {
    saveAuthToken(res.data.token)

    const deleteRes = await Api.deleteUser()

    if (!deleteRes.ok) {
      dispatch(
        createNotification({
          message: deleteRes.error,
          level: 'error',
          title: 'System Error:'
        })
      )
    }
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'System Error:'
      })
    )
  }
}

export const loginWithoutRedirect = (
  email,
  password,
  callback = null
) => async dispatch => {
  clearGlobalData()
  deleteCookieWithoutReload(process.env.REACT_APP_AUTH_TOKEN_COOKIE)

  const mixpanelId = getMixpanelId()

  dispatch({
    type: type.PENDING_SESSION_ACTION,
    payload: 'login'
  })

  const res = await Api.loginUser({
    email,
    password,
    mixpanelId
  })

  if (res.ok) {
    const { verifyAuthy, token, appType } = res.data

    setEmailForMetrics(email)
    dispatch(setAppType(appType))

    if (verifyAuthy) {
      dispatch({
        type: type.TOGGLE_AUTHY_MODAL
      })
    } else {
      saveAuthToken(token)
      callback ? callback() : null
    }

    dispatch({
      type: type.COMPLETED_SESSION_ACTION,
      payload: 'login'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_SESSION_ACTION,
        payload: 'login'
      })
    }, 5000)
  } else {
    dispatch({
      type: type.RESET_SESSION_ACTION,
      payload: 'login'
    })

    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Log in error:'
      })
    )
  }
}

export const toggleAuthyModal = () => dispatch => {
  dispatch({
    type: type.TOGGLE_AUTHY_MODAL
  })
}

export const toggleLoginModal = () => dispatch => {
  dispatch({
    type: type.TOGGLE_LOGIN_MODAL
  })
}

export const verifyAuthyToken = () => async (dispatch, getState) => {
  const { email, password, authyCode } = getState().sessions
  const { m: additionalUserToken, redirectRoute } = parseQueryString(
    window.location.href
  )

  dispatch({
    type: type.PENDING_SESSION_ACTION,
    payload: 'authy'
  })

  const res = await Api.verifyAuthyToken({
    email,
    password,
    token: authyCode,
    redirectRoute
  })

  if (res.ok) {
    saveAuthToken(res.data.token)

    if (additionalUserToken) {
      await dispatch(
        addAdditionalUser({
          token: additionalUserToken,
          email
        })
      )
    } else {
      window.location.hash = res.data.route
    }

    dispatch({
      type: type.COMPLETED_SESSION_ACTION,
      payload: 'authy'
    })
    setTimeout(() => {
      dispatch({
        type: type.RESET_SESSION_ACTION,
        payload: 'authy'
      })
    }, 5000)
  } else {
    dispatch({
      type: type.RESET_SESSION_ACTION,
      payload: 'authy'
    })

    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Authy token error:'
      })
    )
  }
}

export const verifyAuthyTokenWithoutRedirect = () => async (
  dispatch,
  getState
) => {
  const { authyCode } = getState().sessions
  const { email, password } = getState().registrations

  dispatch({
    type: type.PENDING_SESSION_ACTION,
    payload: 'authy'
  })

  const res = await Api.verifyAuthyToken({
    email,
    password,
    token: authyCode
  })

  if (res.ok) {
    saveAuthToken(res.data.token)

    dispatch({
      type: type.COMPLETED_SESSION_ACTION,
      payload: 'authy'
    })

    dispatch({
      type: type.TOGGLE_AUTHY_MODAL
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_SESSION_ACTION,
        payload: 'authy'
      })
    }, 5000)
  } else {
    dispatch({
      type: type.RESET_SESSION_ACTION,
      payload: 'authy'
    })

    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Authy token error:'
      })
    )
  }
}

// Logout
export const logoutUser = () => async (dispatch, getState) => {
  await Api.logoutUser()

  const { appType } = getState().globalData
  safeSegmentCall(() => {
    sendSegmentTrackEvent('Signed Out', {
      product: getProductForSegment(appType)
    })
  })
  window.analytics?.reset()

  clearFlagsData()
  clearChurnZero()
  clearGlobalData()
  clearUsabillaLiveData()
  deleteAuthToken()
  redirectToLogin()

  await queryClient.cancelQueries()
  await queryClient.cancelMutations()

  queryClient.clear()

  dispatch({ type: RESET_GLOBAL_DATA })
}
