import * as type from 'constants/action_types/sidebar'
import { SIDEBAR_LOCKED } from 'constants/sidebar'
import { setLocalData } from 'helpers/storage'

export const toggleSidebar = () => dispatch => {
  dispatch({
    type: type.TOGGLE_SIDEBAR
  })
}

export const toggleSidebarLocked = () => (dispatch, getState) => {
  const { locked } = getState().sidebar
  setLocalData(SIDEBAR_LOCKED, !locked)

  dispatch({
    type: type.TOGGLE_SIDEBAR_LOCKED,
    payload: { locked: !locked }
  })
}

export const resetSidebar = keepOpen => dispatch => {
  dispatch({
    type: type.RESET_SIDEBAR,
    payload: keepOpen
  })
}

export const expandSidebarOption = (name, cnThemeEnabled) => dispatch => {
  dispatch({
    type: type.EXPAND_SIDEBAR_OPTION,
    payload: { name, cnThemeEnabled }
  })
}
